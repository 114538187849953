import React from "react";
import {AppRoutes, publicRoutes} from "@/app/routes";

interface Props {
	path: AppRoutes
}

/**
 * Компонент для отображения других компонентов для роутов строго без авторизации.
 */
export const UnauthorizedRoute : React.FC<Props> = ({path}): React.ReactElement => {
	const _renderRoute = () => {
		const Component = publicRoutes[path]?.component;
		return <Component/>;
	};

	return _renderRoute();
};
