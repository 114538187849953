import React, {useEffect} from "react";

import {Block, Header} from "@/shared/ui/uikit/Layout";

import RegistrationMain from "./RegistartionMain/RegistartionMain";

import "./registration.scss";

const RegistrationPage: React.FC = () => {
	useEffect(() => {
		localStorage.clear();
	}, []);

	return (
		<Block className="registration">
			<Header url="https://zigmund.online/" className="registration__header"/>
			<RegistrationMain />
		</Block>
	);
};

export default React.memo(RegistrationPage);
