import React from "react";

import logo from "@/shared/ui/assets/Icon/HeaderLogo.svg";

import "./styles.scss";

interface Props {
	url: string
	className?: string
}

export const Header = ({url, className}: Props): React.ReactElement => {
	return (
		<header className={`zg__header ${className}`}>
			<a href={url}>
				<img src={logo} alt="zigmund-logo" />
			</a>
		</header>
	);
};
