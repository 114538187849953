import {PayloadAction} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import {AxiosError} from "axios";
import {all, call, put, select, takeLatest} from "redux-saga/effects";

import {actions as calendarActions} from "@/shared/model/calendar/slice";
import {actions as notificationActions} from "@/shared/model/notification/slice";
import {getPsychologistState} from "@/shared/model/psychologist/selectors";
import {actions} from "@/shared/model/psychologist/slice";
import {routePath} from "@/app/routes";
import {getPayoutHistory} from "@/shared/api/v1/billing/requests";
import {IPutIntegrationStateRequestContract} from "@/shared/api/v1/calendar/contracts";
import {
	deleteCustomClient,
	getCustomClients,
	putCustomClient,
	putIntegrationStateApi
} from "@/shared/api/v1/calendar/requests";
import {patchClientTag} from "@/shared/api/v1/client/requests";
import {IPutIntegrationAuthRequest} from "@/shared/api/v1/google-calendar/contracts";
import {putIntegrationAuthApi} from "@/shared/api/v1/google-calendar/requests";
import {
	changePsychologistAvailableStatusApi,
	changePsychologistSkypeUrlApi,
	changePsychologistTelegramOrSkypeApi,
	getPsychologistClientsApi,
	getPsychologistRecentClientsApi
} from "@/shared/api/v1/psychologists/requests";
import {getPsychologist} from "@/shared/api/v2/psychologist/requests";
import {putPsychologistPaymentDataApi} from "@/shared/api/v6/psychologist/requests";
import {GOOGLE_AUTH_URL} from "@/shared/config/constants";
import {faro} from "@/shared/lib/monitoring/faroInit";
import {IPaginationPageFilter} from "@/shared/types/pagination";
import {TProfilePatchSkypeOrTelegram} from "@/shared/types/profile";
import {IPsychologistPaymentData} from "@/shared/types/psychologist";
import {actions as errorModalActions} from "@/widgets/ServerErrorModal/model/slice";

function* getPsychologistClientsSaga() {
	try {
		const psychologistId = +localStorage.getItem("userId");
		const response = yield call(getPsychologistClientsApi, {
			psychologistId
		});
		yield put(actions.getPsychologistClientsSuccess(response.data));
	} catch (e) {
		yield put(actions.getPsychologistClientsFailure());
		// TODO: Тут обработать ошибку запроса к серверу
	}
}

function* getPsychologistRecentClientsSaga(
	action: PayloadAction<{ sort: boolean; periodFrom: string; periodTo: string }>
) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		const response = yield call(getPsychologistRecentClientsApi, {
			psychologistId,
			...action.payload
		});
		yield put(actions.getPsychologistRecentClientsSuccess(response.data));
	} catch (e) {
		yield put(actions.getPsychologistRecentClientsFailure());
	}
}

function* getPsychologistSaga() {
	const userId = +localStorage.getItem("userId");
	try {
		const response = yield call(getPsychologist, {userId});
		if (response.status === 204) {
			window.location.href = routePath.login;
			localStorage.removeItem("accessToken");
			localStorage.removeItem("refreshToken");
			localStorage.removeItem("userId");
		}
		yield put(actions.getPsychologistSuccess(response.data || []));
		yield put(calendarActions.getCalendarSuccess(response.data?.calendar));
		// Sentry.setUser({name: `${response.data.firstName} ${response.data.lastName}`, email: response.data.email, id: userId.toString()});
		faro.api.setUser({
			id        : String(userId) || "undefined",
			attributes: {
				role: "psychologist"
			}
		});
	} catch (error) {
		// TODO добавить обработку кода ошибки.
		// try {
		//   const responseRefreshToken = yield call(refreshToken);
		//   localStorage.setItem(
		//     'accessToken',
		//     responseRefreshToken.data.accessToken
		//   );
		//   const response = yield call(getPsychologist, { psyID: psychologistId });
		//   yield put(actions.getPsychologistSuccess(response.data));
		// } catch (refreshError) {
		//   // TODO: Catch fetch psychologist error
		//   yield put(actions.getPsychologistFailure());
		// }
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		// Sentry.setUser(null);
		yield put(actions.getPsychologistFailure());
		yield put(calendarActions.getCalendarFailure(requestError));
		if (axiosError.response?.status === 500) {
			yield put(errorModalActions.showErrorModal());
		} else {
			yield put(notificationActions.notifyError(requestError));
		}
	}
}

function* changePsychologistAvailabilityStatusSaga(
	action: PayloadAction<() => void>
) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		const psychologist = yield select(getPsychologistState);
		const isShownInAutomatch = !psychologist.profile.data.isShownInAutomatch;
		yield call(changePsychologistAvailableStatusApi, {
			psyID             : psychologistId,
			isShownInAutomatch: !psychologist.profile.data.isShownInAutomatch
		});
		yield put(
			actions.changePsychologistAvailabilitySuccess({
				...psychologist.data,
				isShownInAutomatch
			})
		);
		action.payload();
	} catch (error) {
		// TODO: Catch fetch change psychologist availability error
	}
}

function* changePsychologistTelegramOrSkypeSaga(action: PayloadAction<TProfilePatchSkypeOrTelegram>) {
	try {
		const {type, data} = action.payload;
		const psychologistId = +localStorage.getItem("userId");
		yield call(changePsychologistTelegramOrSkypeApi, {
			psyID : psychologistId,
			[type]: data
		});
		yield put(actions.CHANGE_PSYCHOLOGIST_TELEGRAM_OR_SKYPE_SUCCESS());
		yield put(actions.getPsychologist());
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Ошибка при сохранении. Попробуйте позже."
		};
		if (axiosError.response?.status === 500) {
			yield put(errorModalActions.showErrorModal());
		}
		yield put(actions.CHANGE_PSYCHOLOGIST_TELEGRAM_OR_SKYPE_FAILURE(requestError));
	}
}

function* changePsychologistSkypeUrlSaga({payload}: PayloadAction<string>) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		yield call(changePsychologistSkypeUrlApi, {
			psyID      : psychologistId,
			skypeInvite: payload
		});
		yield put(actions.CHANGE_PSYCHOLOGIST_SKYPE_URL_SUCCESS());
		yield put(actions.getPsychologist());
	} catch (error) {
		console.error(error, "error");
		yield put(actions.CHANGE_PSYCHOLOGIST_SKYPE_URL_FAILURE());
	}
}

function* patchClientTagSaga(
	action: PayloadAction<{
		clientId: number
		tag: string
		onSuccess: () => void
		onError: () => void
	}>
) {
	const {tag, clientId} = action.payload;
	try {
		yield call(patchClientTag, {tag, clientId});
		yield put(actions.patchClientTagSuccess({tag, clientId}));
		action.payload.onSuccess();
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Ошибка при сохранении тега. Попробуйте позже."
		};
		yield put(actions.patchClientTagFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
		action.payload.onError();
	}
}

function* putIntegrationStateSaga(
	action: PayloadAction<IPutIntegrationStateRequestContract>
) {
	try {
		yield call(putIntegrationStateApi, action.payload);
		yield put(actions.putIntegrationStateSuccess(action.payload));
	} catch (error) {
		const axiosError = error as AxiosError;
		// Необходимо повторить авторизацию в гугле.
		if (axiosError.response?.status === 401) {
			window.location.href = GOOGLE_AUTH_URL;
		} else {
			const requestError = {
				status : axiosError.response?.status,
				message:
          "Ошибка при сохранении состояния синхронизации. Попробуйте позже."
			};
			yield put(actions.putIntegrationStateFailure(requestError));
			yield put(notificationActions.notifyError(requestError));
		}
	}
}

function* putIntegrationAuthSaga(
	action: PayloadAction<IPutIntegrationAuthRequest & { onSuccess: () => void }>
) {
	try {
		const response = yield call(putIntegrationAuthApi, action.payload);
		const changeIntegrationData: IPutIntegrationStateRequestContract = {
			calendarId   : action.payload.calendarId,
			integrationId: "google",
			account      : response.data.googleAccount,
			isActive     : true,
			isFree       : true,
			isMyClient   : true,
			isOther      : true
		};
		yield call(putIntegrationStateApi, changeIntegrationData);
		action.payload.onSuccess();
		yield put(actions.putIntegrationStateSuccess(changeIntegrationData));
		yield put(actions.putIntegrationAuthSuccess());
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Ошибка при выполнении синхронизации. Попробуйте позже."
		};
		yield put(actions.putIntegrationAuthFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* getCustomClientsSaga() {
	try {
		const psychologist = yield select(getPsychologistState);
		const response = yield call(getCustomClients, {
			calendarId: psychologist.profile.data?.calendar?.calendarId
		});
		yield put(actions.getCustomClientsSuccess(response.data));
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.getCustomClientsFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* putCustomClientSaga(action: PayloadAction<string>) {
	try {
		const psychologist = yield select(getPsychologistState);
		const calendarId = psychologist.profile.data?.calendar?.calendarId;
		const name = action.payload;
		const response = yield call(putCustomClient, {calendarId, name});
		yield put(
			actions.putCustomClientSuccess({id: response.data.id, calendarId, name})
		);
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.putCustomClientFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* deleteCustomClientSaga(
	action: PayloadAction<{ id: number; onSuccess: () => void }>
) {
	try {
		yield call(deleteCustomClient, {id: action.payload.id});
		yield put(actions.deleteCustomClientSuccess(action.payload.id));
		action.payload.onSuccess();
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.deleteCustomClientFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* putPsychologistPaymentDataSaga (
	action: PayloadAction<IPsychologistPaymentData>
) {
	try {
		const {psychologistId, bankAccount, paymentAccount} = action.payload;
		const response = yield call(putPsychologistPaymentDataApi, {
			psychologistId,
			bankAccount,
			paymentAccount
		});
		if (response.status === 200) {
			yield put(actions.getPsychologist());
			yield put(actions.putPsychologistPaymentDataSuccess());
		}
	} catch (error) {
		yield put(actions.putPsychologistPaymentDataFailure());
	}
}

function* getPsychologistPaymentHistorySaga(action: PayloadAction<IPaginationPageFilter>) {
	try {
		const {page} = action.payload;
		const psychologistId = +localStorage.getItem("userId");
		const response = yield call(getPayoutHistory, {
			psychologistId: psychologistId,
			page
		});
		yield put(actions.getPsychologistPaymentHistorySuccess(
			response.data || []
		));
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Ошибка при загрузке транзакций"
		};
		yield put(actions.getPsychologistPaymentHistoryFailure(requestError));
	}
}

function* saga() {
	yield all([
		takeLatest(actions.getPsychologistClients, getPsychologistClientsSaga),
		takeLatest(
			actions.getPsychologistRecentClients,
			getPsychologistRecentClientsSaga
		),
		takeLatest(actions.getPsychologist, getPsychologistSaga),
		takeLatest(
			actions.changePsychologistAvailability,
			changePsychologistAvailabilityStatusSaga
		),
		takeLatest(actions.CHANGE_PSYCHOLOGIST_TELEGRAM_OR_SKYPE, changePsychologistTelegramOrSkypeSaga),
		takeLatest(actions.CHANGE_PSYCHOLOGIST_SKYPE_URL, changePsychologistSkypeUrlSaga),
		takeLatest(actions.patchClientTag, patchClientTagSaga),
		takeLatest(actions.putIntegrationState, putIntegrationStateSaga),
		takeLatest(actions.putIntegrationAuth, putIntegrationAuthSaga),
		takeLatest(actions.getCustomClients, getCustomClientsSaga),
		takeLatest(actions.putCustomClient, putCustomClientSaga),
		takeLatest(actions.deleteCustomClient, deleteCustomClientSaga),
		takeLatest(actions.putPsychologistPaymentData, putPsychologistPaymentDataSaga),
		takeLatest(actions.getPsychologistPaymentHistory, getPsychologistPaymentHistorySaga)
	]);
}

export default saga;
