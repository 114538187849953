import React from "react";
import {useNavigate} from "react-router-dom";

import {AppRoutes, routePath} from "@/app/routes";
import img404 from "@/shared/ui/assets/404.png";
import CustomButton from "@/shared/ui/uikit/CustomButton";
import {Button} from "@/shared/ui/uikit/Form";
import {Column, Content, FlexContainer, Header, Row} from "@/shared/ui/uikit/Layout";

import styles from "./NotFound.module.scss";

/**
 * Страница 404.
 */
export const NotFound = (): React.ReactElement => {
	const navigate = useNavigate();

	const goToMain = () => {
		navigate(routePath[AppRoutes.DEFAULT]);
	};
	const goToTelegram = () => {
		window.open("https://t.me/ZigmundOnline_bot", "_blank");
	};

	return (
		<>
		  <Header url={routePath[AppRoutes.DEFAULT]} />
			<Content className={styles.notFound}>
				<div className={styles.mainWrapper}>
					<div className={styles.main}>
						<div className={styles.header}>
							<h1 className={styles.title}>
                Ошибка 404
							</h1>
						</div>
						<p className={styles.subHeader}>
                Упс, страница сейчас недоступна, попробуйте зайти немного позже
						</p>
						<CustomButton className={styles.btn} type="primary" onClick={goToMain}>
                Вернуться на главную
						</CustomButton>
					</div>
					<div className={styles.imageContainer}>
						<img src={img404} alt="404Image" className={styles.image}/>
					</div>
				</div>
			</Content>
			<div className={styles.sos}>
				<FlexContainer>
					<Column className={styles.sosCol}>
						<Row p="none">
							<b className={styles.sosTitle}>Срочная ситуация</b>
						</Row>
						<Row p="small">
              Если у вас неотложный вопрос, вы можете&nbsp;
							<Button className={styles.link} variant="link" color="primary" onClick={goToTelegram}>
                написать нам в Телеграм
							</Button>
						</Row>
					</Column>
				</FlexContainer>
			</div>
		</>
	);
};
