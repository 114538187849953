import React, {useCallback, useEffect, useMemo, useState} from "react";

import {landingURL} from "@/shared/config/constants";
import {GTM_ANALYTICS} from "@/shared/lib/analytics/gtmEvents";
import {handleGTM} from "@/shared/lib/analytics/handleGTM";
import {CustomPopup} from "@/shared/ui/uikit/CustomPopup/custom-popup";

import "./registration-popup.scss";

const BANNER_POPUP_CODENAME = "registrationPopUp";

const RegistrationPopUp = () => {
	const [isPopUpActive, setIsPopUpActive] = useState(true);

	const handleCloseBanner = useCallback(() => {
		handleGTM(["click_meet_requirements", GTM_ANALYTICS.MEET_REQUIREMENTS]);
		setIsPopUpActive(false);
		sessionStorage.setItem(BANNER_POPUP_CODENAME, "close");
	}, [isPopUpActive]);

	useEffect(() => {
		const banner = sessionStorage.getItem(BANNER_POPUP_CODENAME);
		if (banner === "close") {
			setIsPopUpActive(false);
		}
	});

	const popUpContent = useMemo(() => {
		return <>
			<ol className="registration-pop-up__requirements-list">
				<li>Высшее образование по психологии (бакалавриат, магистратура или специалитет)</li>
				<li>Дополнительное обучение по подходу от 500 часов</li>
				<li>Опыт консультирования от 3-х лет</li>
				<li>Прохождение личной терапии от 50 часов и супервизий от 50 часов</li>
			</ol>
			<p className="registration-pop-up__text">
				<strong>Если соответствие требованиям соблюдено — мы ждём вашу заявку!</strong> Если понимаете, что сейчас не проходите по требованиям — мы будем вас ждать, когда это соответствие будет соблюдено</p>
		</>;
	}, []);

	const redirectOnClose = useCallback(() => {
		handleGTM(["click_not_meet_requirements", GTM_ANALYTICS.NOT_MEET_REQUIREMENTS]);
		window.location.href = landingURL;
	}, []);

	if (!isPopUpActive) { return null; }

	return <CustomPopup className="registration-pop-up"
		isOpened={true}
		onClose={redirectOnClose}
		onSubmit={handleCloseBanner}
		onCloseText="Приду к вам позже"
		title="Ознакомьтесь с требованиями по отбору специалистов"
		isLoading={false}
		onSubmitText="Я вам подхожу"
		closeOnClickAway={false}
		isCloseBtnVisible={false}
	>
		{popUpContent}
	</CustomPopup>;
};

RegistrationPopUp.displayName = "RegistrationPopUp";

export default React.memo(RegistrationPopUp);
